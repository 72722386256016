.trusted-clients-section {
  padding: 181px 0;
  margin: 0%;
  margin-top: -236px;
  background-color: rgba(221, 253, 234, 1);
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2%;
    
}

.slick-slide {
  z-index: initial !important;
}

.section-header {
  margin-bottom: 40px;
}

.black-text {
  color: #274C5B;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
}

.gradient-text {
  background: linear-gradient(to left,
      #3FB871,
      rgb(247, 182, 24),
      rgb(63, 184, 113),
      rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.subtext {
  color: #717171;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  width: 100%;
}

.image-container {
  padding: 20px;
  margin-top: 50px;
  display: inline-block;
  white-space: nowrap;
  /* Maintain inline block and prevent line breaks */
  margin-right: 10px;
}

.client-image {
  max-width: 100%;
  height: auto;
  max-height: 100px;
  border-radius: 10px;
  
  /* transition: transform 0.3s ease-in-out; */
}

.slick-slider {
  margin-top: -4%;
}

/* .client-image:hover {
  transform: scale(1.05);
} */

/* Media Query for Small and Medium Devices */
@media screen and (max-width: 1200px) {
  .client-image {
    max-height: 80px;
  }

  .black-text {
    font-size: 28px;
  }

  .black-text {
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    ;
    font-size: 36px;
    font-weight: 600;
    line-height: 0.5px;
    font-family: 'Poppins', sans-serif;
  }
}

@media screen and (max-width: 768px) {
  .trusted-clients-section{
    padding-top: 57%;
  }
  .client-image {
    max-height: 50px;
  }

  .section-header {
    margin-bottom: 20px;
  }

  .black-text {
    font-size: 40px;
    text-align: left;

  }

  .gradient-text {
    color: transparent;
    line-height: 2;
    margin-left: -2px;
    text-indent: 3px;
    width: 100%;
  }

  .subtext {
    font-size: 16px;
    width: 100%;
    font-weight: 400;
    text-align: left;
  }

}

/* Add this style to remove the bottom line */
.slick-list {
  border-bottom: none !important;
}

/* Remove slick-arrow styling */
.slick-prev,
.slick-next {
  display: none !important;
}