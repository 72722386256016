.testimonialContainer {
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8em;
    padding-bottom: 5em;
    padding-left: 10%;
    padding-right: 10%;
    font-family: 'Poppins', sans-serif;
}

.testimonialContainer h3 {
    font-size: 45px;
    color: #274c5b;
    font-weight: 600;
    padding-left: 10%;
    text-align: center;
    padding-bottom: 20px;
    padding-right: 10%;
}

.testimonialContainer h3 span {
    background: linear-gradient(to left, #3fb871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.testimonialContainer .desc-text {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
    color: #525C60;
    margin-bottom: 30px;
}

.carousel-testimony {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 15rem;
}

.carousel-item-testimony {
    height: 90%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    margin-right: 10px;
    margin-left: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;

    margin-top: 10px;
    margin-bottom: 10px;
}

.react-multi-carousel-track {
    margin-bottom: 70px !important;
}

.react-multi-carousel-dot--active button {
    background-color: rgba(63, 184, 113, 1) !important;
}

.react-multi-carousel-dot button {
    background-color: rgba(199, 199, 199, 1);
}

.gZzsDe {
    margin-top: 10px !important;
    bottom: -40px !important;
}

.img-testimony {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: -1%;
    object-fit: cover;
    object-position: center;
}

.top-item-testimony {
    display: flex;

    margin-left: 40px;
    padding-top: 20px;
    align-items: center;
    align-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    margin-right: 10px;
}

.top-item-testimony .name-title {
    margin-top: 4%;
    color: #274c5b;
}

.bottom-item-testimony {
    display: flex;

    margin-left: 40px;
    padding-top: 20px;
    align-items: center;
    padding-bottom: 20px;
    font-size: 15px;

    margin-right: 10px;
}

@media (max-width: 768px) {
    .testimonialContainer h3 {
        font-size: 30px;
        font-weight: 600;
        text-align: left;

    }

    .testimonialContainer {
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;

    }

    .testimonialContainer .desc-text {
        text-align: left;
        font-weight: 400;
        font-size: 15px;
        width: 80%;
        color: #525C60;
        margin-bottom: 30px;
    }

    .carousel-item-testimony {
        width: 100%;
        margin: 0;
        padding-bottom: 50px;
    }

    .carousel-testimony {
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 5rem;
    }




}