.sel-c {
    width: 100%;
    margin-top: 80px;
    position: relative;
    margin-bottom: 100px;
    margin-top: 17%;
}

.sel-wrapper {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    background: rgba(63, 184, 113, 1);
    justify-items: center;
    align-items: center;
   
    height: 307px;
    flex-shrink: 0;
    border-radius: 5px;
    padding-right: 26px;
}

.sel-img {
    width: 26%;
    height: 576px;
    flex-shrink: 0;
}

.sel-images {
    position: absolute;
    top: -140px;
    left: -31%;
    width: 100%;
    height: 576px;
}

.sel-wr {
    display: flex;
    /* justify-content: flex-end; */
    /* width: 60%; */
    /* margin-left: 30%; */
    gap: 20px;
}


.sel-tex {
    display: flex;
    flex-direction: column;
    width: 562px;
    

}

.sel-heading {
    width: 100%;
}

.sel-heading>h2 {
    color: var(--Color, #FFF);
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sel-para {
    width: 569px;
}

.sel-para>p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.ios-download {
    background-color: white;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    border-radius: 10px;
}

.android-download {
    background-color: rgba(63, 184, 113, 1);
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid white;
}

.downLoad {
    width: 28%;
}

@media (max-width: 768px) {
    .sel-c {
        margin-top: 300px;


    }

    .sel-tex {

        width: 100%;
        justify-content: center;
    }

    .sel-heading {
        width: 100%;
    }

    .sel-heading>h2 {
        color: var(--Color, #FFF);
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .sel-wrapper {
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        justify-content: center;
        height: 921px;
        border-radius: 5px;

    }

    .sel-tex {
        display: flex;
        flex-direction: column;
    }

    .sel-para {
        width: 100%;
    }

    .sel-para>p {
        color: #FFF;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .sel-images {

        position: absolute;
        top: -250px;
        left: 0;
        width: 100%;
        height: 484px;
        flex-shrink: 0;


    }

    .sel-tex {
        width: 100%;

    }

    .sel-img {

        height: auto;


    }

    .sel-wr {
        margin-top: 0;
        flex-direction: column;
        width: 100%;
        /* justify-content: center; */
        margin-right: 150px;
        height: auto;
        padding-right: 6rem;

    }

    .sel-wr .sel-tex {}

    .sel-h {
        text-align: left;
        font-size: 30px;
        width: 359px;
    }

    .downLoad {
        width: 70%;
    }

}

@media (max-width: 820px) {

    .sel-wrapper {
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        justify-content: center;
        height: 921px;
        border-radius: 5px;
        margin: 0;
        padding: 40px;

    }

    .ios-download {
        gap: 10px;
    }

    .android-download {
        gap: 10px;
    }

    .sel-p {
        text-align: left;
    }

    .sel-images {}

    .sel-tex {}

    .sel-wr {
        margin-top: 0;
        flex-direction: column;
        width: 100%;
        /* justify-content: center; */
        /* margin-right: 150px; */
        height: auto;
        padding-right: 0;
        margin: 0;
    }

}

@media (max-width: 320px) {



    .sel-wrapper {
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        justify-content: center;
        height: 921px;
        border-radius: 5px;
    }

    .sel-p {
        text-align: center;
    }

    .sel-images {

        width: 335.23px;
        height: 484px;



    }

    .sel-wr {
        margin-top: 0;
        flex-direction: column;
        width: 100%;
        /* justify-content: center; */
        margin-right: 150px;
        height: auto;
        padding-right: 4rem;
    }

    .sel-tex {}
}