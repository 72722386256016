/* VisionAndMission.css */

.vision-mission-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.flourishing-text {
  color: #274C5B;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vision-section,
.mission-section {
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.vision-section,
.missiondd-section {
  text-align: center;
  margin-bottom: 20px;
  margin-left: 12%;
  margin-right: 10%;
}

.image-container img {
  width: 75%;
  max-width: 75%;
  height: auto;
  border-radius: 8px;
  /* Optional: Add rounded corners to the image */
}

.image-coccntainer img {
  width: 38%;
  max-width: 38%;
  height: auto;
  border-radius: 8px;
  
  margin-left: -3%;
}


.text-contaiinerxxddzz {
  text-align: left;
  margin-left: -2px;
  width: 82%;
  color: #274C5B;
  font-family: 'Poppins', sans-serif;
  flex: 1;
  text-align: start;

}



@media (min-width: 768px) {

  /* Apply styles for screens wider than 768px */
  .vision-mission-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .mission-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .missiondd-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mission-section .text-containersd,
  .mission-section .image-container {
    flex: 1 1;
    margin-right: -45px;
    margin-left: 63px;
  }

  
  .mission-section .image-container {
    flex: 1 1;
    margin-right: -45px;
    margin-left: 53px;
  }

  .missiondd-section .text-containersd{
    flex: 1 1;
    margin-right: -55px;
    margin-left: 637px;
  }

}

/* Vision and Mission */
.vision-section {
  text-align: center;
  padding-top: 3%;
}

.vision-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #274C5B;
  margin-bottom: 20px;
  margin-left: -4%;
  margin-right: 6%;
}

.missionImage {
  
  margin-right: 2%;
  margin-top: -31%;
}

.vision-mission-contai {
  flex-direction: column;
  align-items: center;
}



.minf {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: -47%;
}

.minfs {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10%;

}

.vision-heading .vision {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.vision-heading .mission {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;

}

/* Paragraph styles */
.vision-paragraph {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #525C60;
  margin-bottom: 40px;
  margin-left: -3%;
  margin-right: 0%;
}

/* Responsive styles for small devices */
@media screen and (max-width: 767px) {
  .vision-heading {
    margin-left: -3%;
    margin-right: 4%;
    text-align: left;
  }


  .missionImage {
    margin-left: -41%;
    margin-right: 2%;
    margin-top: 21%;
}
  .minfs {
    margin-left: 4%;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }

  .missiondd-section {
    flex-direction: column-reverse;
    align-items: center;
  }


  .minf {

    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -4%;

  }

  .textntainer {

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -5%;

  }

  .text-containep {

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    width: 109%;

  }

  .flourishing-text {

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

  }

  .vision-paragraph {
    font-size: 16px;
    line-height: 20px;
  }
}

/* VisionStatement */

.vision-paragrap {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #525C60;
  margin-bottom: 40px;
  margin-left: 19%;
  margin-right: 20%;
}

/* Specific color for part of the paragraph */
.vision-paragraph span.flourishing-text {
  color: #274C5B;
}

/* Responsive styles for small devices */
@media screen and (max-width: 767px) {
  .vision-heading {
    font-size: 30px;
    line-height: 36px;
  }

  .vision-paragraph {
    font-size: 16px;
    line-height: 20px;
  }

  .image-coccntainer img {
    max-width: 129%;
    height: auto;
    border-radius: 8px;
    margin-top: 10%;
    margin-left: -22%;
    width: 115%;
}
}

@media screen and (max-width: 480px) {
  .vision-heading {
    font-size: 24px;
    line-height: 28px;
    width: 103%;
  }

  .vision-paragraph {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    width: 109%;
    margin-left: -1%;
  }
}

@media screen and (max-width: 767px) {

  .vision-section,
  .mission-section {
    flex-direction: column;
  }

  .image-container,
  .text-containerxxddzz {
    width: 144% !important;
    margin-left: -25%;
    margin-right: 5%;
  }
  
}