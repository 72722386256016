/* NewStrategicComponent.css */

.new-strategic-component {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
}

.new-con {
    width: 25%;

    margin-left: 1%;
    margin-right: 10%;

}

.new-content p a {
    text-decoration: none;
}

.new-active {
    font-weight: 600;
}

.new-imaContain h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
    color: #274C5B;
    width: 305%;
    text-align: center;
    margin-top: 79px;
    margin-bottom: 6px;
    margin-left: -29%;
}

.new-paraContainer p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #525C60;
    width: 308%;
    margin-left: -32%;
}

.new-ptreu {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.new-header.new-active+.new-content {
    max-height: 500px;
    /* Set a maximum height for the slide effect */
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    /* Add smooth transition for max-height */
    padding: 25px;
}

.new-header.new-active+.new-content::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    /* Adjust as needed */
    width: 10px;
    /* Adjust as needed */
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    box-shadow: 3px 0 5px rgb(247, 182, 24);
    /* Adjust shadow as needed */
}

.new-content {
    width: 100%;
}

.new-header.new-active .new-content {
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2);
}

.new-ptreu h1 {
    font-size: 1.8em;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.new-ptreu h1::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    /* Adjust as needed */
    width: 5px;
    /* Adjust as needed */
    background-color: red;
    box-shadow: 3px 0 5px rgba(255, 0, 0, 0.5);
    /* Adjust shadow as needed */
}

.new-ptreu p {
    font-size: 1.2em;
    color: #555;
    width: 100%;
}

.new-content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 12%;
}

.new-headers-container {
    width: 410%;
    max-width: 491%;
    margin-top: -5%;
}

.new-active-title {

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;
}

.new-content-text {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    padding: 12px;
    text-align: left;
    cursor: pointer;
    margin-top: -6%;
}

.Farmscape {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #525C60;
    margin-right: 44%;
    margin-right: -10%;
    text-align: justify;
    margin-top: 28px;
    cursor: pointer;


}


.labora {
    margin-right: 10%;
}

.Collab {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.new-header-container {
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: #F3F3F3;
}

.new-header-active-color.new-active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    /* Adjust the width as needed */
    background-color: orange;
    /* Change the color as needed */
}

.new-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    background-color: #F3F3F3;
    cursor: pointer;
}

.new-header.new-active {
    background-color: #F3F3F3;
}

.new-title {
    font-size: 1.2em;
    font-weight: 600;
}




.Partn {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.new-radio-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    /* background-color: #3FB871; */
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    width: 30px;
    height: 31px;
    color: white;
    font-weight: 700;
}

.new-header.new-active .new-radio-icon {
    /* background-color: #643ecb; */
    /* Change color when active */
    transform: rotate(180deg);
    /* Rotate the icon when active */
}


.new-radio-icon::before {
    content: '\00a0';
    /* Add some space for better appearance */
}

.new-radio-icon.clicked::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    /* Adjust as needed */
    width: 5px;
    /* Adjust as needed */
    background-color: red;
    content: '';
}

.new-header.new-active .new-content {
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    /* Add box-shadow to the left of the content when active */
}

@media screen and (min-width: 768px) {
    .new-strategic-component {
        margin-left: 10%;
        margin-right: 10%;
        max-width: 100%;
        margin-bottom: 84px;
    }

    .new-con,
    .new-ptreu {
        width: 25%;
    
        margin-left: 21%;
        margin-right: 10%;
    }

  
}

@media screen and (max-width: 767px) {
    .new-strategic-component {
        margin-left: 5%;
        margin-right: 5%;
    }

    .new-imaContain h1 {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-size: 30px;
        line-height: 1.2;
        margin-left: 0%;

    }
    .partnerships-subtitledd{
        text-align: left !important;
    }

    .new-paraContainer p {
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 18px;
        Line-height: 24px;
        margin-left: 0%;
    }
    
    

    .new-content-container {
        flex-direction: column;
        margin-top: 8%;
    }

    .new-ptreu,
    .new-con {
        width: 100%;
    }

    .new-headers-container {
        width: 100%;
        max-width: 100%;
        margin-top: 0;
    }

    .new-ptreu h1::before {
        left: 0;
    }

    .new-header-container {
        margin-top: 10px;
    }

    .new-content,
    .new-header-active-color {
        width: 100%;
    }

    .new-ptreu h1,
    .new-ptreu p,
    .Farmscape,
    .Collab {
        margin-right: 0;
    }

    .Collab {
        margin-right: 0;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .Farmscape {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }

    .new-title {

        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }


    .new-radio-icon {
        width: 16px;
        height: 16px;
        border-radius: 54%;
        font-size: 13px;
    }

    .new-radio-icon.clicked::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        /* Adjust as needed */
        width: 5px;
        background-color: #3FB871;
        content: '';
        border-radius: 50%;
        ;
    }

    .new-content-text {

        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

    }


}

.current-partnership-container {
    margin-left: 10%;
    margin-right: 10%;
}

.partnerships-subtitledd, .partnerships-subtitle
 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center ;
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    width: 100%;
    margin-bottom: 0%;
}

.current-partnership-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 3%;
    width: 82%;
    margin-left: 13%;
    color: #525C60;
}

.partnership-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
}


@media (max-width: 768px) {
    .partnership-row {
        flex-direction: column;
        align-items: center;
        /* Center items in the column */
    }

    .partnerships-subtitle
     {
        width: 119%;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 14%;
        margin-right: 102%;

    }

    .current-partnership-container p {

        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 2%;
        width: 117%;

    }
}

.ourachivements {
    font-family: 'Poppins', sans-serif;
    margin-left: 10%;
    margin-right: 10%;
}

.ourachivements .header {
    text-align: center;
    margin-top: 10em;
    margin-bottom: 5em;
}

.ourachivements .header h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
}


.ourachivements .header h2 span.colored {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.ourachivements .header .desc-text {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;
}

.our-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 13%;
    margin-right: 13%;
    margin-bottom: 20%;
}


.our-containers .item {
    flex: 0 0 calc(33.33% - 20px);
    margin: 10px;
    margin-top: 10%;
}

.our-containers .item .icon {
    display: flex;
    justify-content: center;
}

.our-containers .item .num {
    color: #274C5B;
    text-align: center;
    font-size: 70px;
    font-weight: 600;
}

.our-containers .item .desc {
    color: #274C5B;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}


@media (max-width: 768px) {
    .our-containers .item {
        flex: 0 0 calc(50% - 20px);
    }

    .ourachivements .header h2 {
        font-size: 30px;
        text-align: left;
    }

    .ourachivements .header .desc-text {
        font-size: 18px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    }

    .our-containers {
        margin-left: -5%;
        margin-right: -5%;
    }

    .our-containers .item .icon img {
        width: 55%;
    }
    .current-partnership-container {
        margin-left: 1%;
        margin-right: 10%;
    }


    .our-containers .item .num {
        font-size: 40px;
    }

    .our-containers .item .desc {
        font-size: 18px;
    }


}