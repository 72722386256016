.super-trustedClient {
  margin-top: 154px;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10%;
}

.super-trustedClient .textt-cont {
  width: 60%;
  margin-top: 3%;
}

.super-trustedClient .cont-image {
  width: 100%;
}

.super-trustedClient .cont-image img {
  width: 55%;
  margin-left: 25%;
}


.super-trustedClient .textt-cont h1 {
  font-size: 45px;
  font-weight: 700;
  color: #274C5B;
  margin-bottom: 30px;
  white-space: nowrap;
}

.super-trustedClient .textt-cont p {
  color: #525C60;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

}

.super-trustedClient .textt-cont button {
  background-color: #3FB871;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  position: relative;
}

.super-trustedClient .textt-cont button img {
  position: absolute;
  right:9px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px; /* Adjust this value as needed */
}


.super-trustedClient .text-cont button:hover {
  background-color: #F7B618;
}

.graxdient-tet {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;


}
.gradient-texxt {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
  /* margin-top: -34%; */
}

@media screen and (max-width: 768px) {
  .super-trustedClient {
    margin-left: 0;

    flex-direction: column;
    margin-top: 0;
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .super-trustedClient .textt-cont{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }
  .gradient-texxt {
    color: transparent;
    /* line-height: 2; */
    margin-left: -2px;
    text-indent: 3px;
    width: 100%;
}

  .super-trustedClient .textt-cont button {
    background-color: #3FB871;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    padding: 15px;
    border: none;
    border-radius: 10px;
    white-space: nowrap
  }



  .super-trustedClient .textt-cont h1 {
    margin-bottom: 30px;
    white-space: unset;
    font-size: 40px;
  }

  .super-trustedClient .text-cont p {
    font-size: 18px;
    font-weight: 400;
  }

  .super-trustedClient .textt-cont button {
    font-weight: 600;
    font-size: 15px;
    padding: 10px;
  }

  .super-trustedClient .cont-image {
    width: 100%;
    overflow: hidden;
  }

  .super-trustedClient .cont-image img {
    width: 68%;
    height: auto;
    object-fit: contain;
    margin-left: 13%;
    
  }

  }

