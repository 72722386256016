.Eng-c {
    margin-top: 100px;
    width: 100%;
}

.Eng-w {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;

}

.Eng-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    margin-top: 60px;

}

.Eng-heading {
    width: 621px;
    height: 162px;
    flex-shrink: 0;
}

.Eng-heading>h2 {
    color: rgba(39, 76, 91, 1);
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    
    letter-spacing: 0em;
    text-align: left;

    

}

.Eng-para {
    width:100%;
    height: 176px;
    flex-shrink: 0;
}

.Eng-para>p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 40px;
    color: #525C60;

}

.Eng-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Eng-images {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 544px;
    flex-shrink: 0;
}

.bg-text {
    background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%),
        linear-gradient(0deg, #274C5B, #274C5B);

    -webkit-background-clip: text;
    color: transparent;
}

@media (max-width: 768px) {
    .Eng-c {
        margin-top: 400px;
        width: 100%;
    }

    .Eng-w {
        margin-top: 40px;
        flex-direction: column;

    }

    .Eng-c {
        margin-top: 50%;
        width: 100%;
    }

    .Eng-heading {
        width: 100%;
        height: 211px;
    }

    .Eng-heading>h2 {

        color: var(--sec, #274C5B);
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        /* 146.667% */

    }

    .Eng-para {
        width: 100%;
        height: auto;
    }

    .Eng-para>p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */

    }

    .Eng-c.h1 {
        font-size: 30px;
        width: 359px;
        height: auto;
        text-align: -webkit-left;
        font-weight: 600;
        line-height: 44px;
        font-style: normal;
    }

    .Eng-images {
        width: 100%;
        height: 398px;
        flex-shrink: 0;
    }

    .Eng-text {
        gap: 80px;

    }

}

@media (max-width: 820px) {
    .Eng-w {
        margin-top: 40px;
        flex-direction: column;

    }

    .Eng-c {
        margin-top: 50%;
        width: 100%;
    }

    .Eng-h {

        font-size: 30px;
        line-height: 44px;
        text-align: center;

        padding-top: 40px;

    }

    .Eng-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */


    }

    .Eng-h {

        color: var(--sec, #274C5B);
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        text-align: center;

    }

    .Eng-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */

    }

}

@media (max-width: 320px) {

    .Eng-w {
        margin-top: 25px;
        flex-direction: column;

    }

    .Eng-c {
        margin-top: 200px;
        width: 100%;
    }

    .Eng-h {

        color: var(--sec, #274C5B);
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        text-align: center;

    }

    .Eng-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */

    }

}