.t-c {
    width: 100%;
    margin-top: 100px;
}

.t-wrapper {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.t-header {
    width: 771px;
    height: 162px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.t-header > h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(39, 76, 91, 1);

}

.t-w {
    width: 100%;
    display: flex;
    gap: 100px;
    margin-top: 60px;

}

.t-img {
    width: 100%;
   
}

.t-images {
    width: 594px;
height: 489px;
flex-shrink: 0;
}
.t-para{
    width: 100%;
height: 208px;
flex-shrink: 0;
}
.t-text {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.t-para > p {
    color: #525C60;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ;
}
.t-btnW{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 11%;
}

.t-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3FB871;
    width: 300px;
    height: 50px;

    padding: 40px;
    border-radius: 5px;
    gap: 5px;

    color: #fff;
    font-weight: 600;
    font-size: 18px;
    margin-top: 100px;
    padding: 15px;
    border: none;

}

.bg-text {
    background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%),
        linear-gradient(0deg, #274C5B, #274C5B);

    -webkit-background-clip: text;
    color: transparent;
}

@media (max-width: 768px) {
    .t-w {
        flex-direction: column-reverse;

    }

    .t-text {
        width: 100%;
        gap: inherit;
    }

    .t-header {
        width: 100%;
        height: 162px;
        flex-shrink: 0;
       
    }
    .t-header > h2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    
    }
    .t-images {
        width: 100%;
        height: 319px;
        flex-shrink: 0;
    }
    .t-para{
        width: 100%;
        height: 208px;
        flex-shrink: 0;
    }
    .t-para > p {
        color: #525C60;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    
        
    }

}




@media (max-width: 820px) {

    .t-w {
        flex-direction: column-reverse;

    }

    .t-p {
        text-align: center;
        font-size: 15px;
    }

    .t-text {
        width: 100%;
    }

    .t-h {

        font-size: 30px;
        line-height: 30px;


    }
}

@media (max-width: 320px) {

    .t-w {
        flex-direction: column;

    }

    .t-text {
        width: 100%;
    }

    .t-h {

        font-size: 30px;
        line-height: 30px;


    }
}