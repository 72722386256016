.tailored-benefits {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
    margin-bottom: 2em;
    font-family: 'Poppins', sans-serif;
  }
  
  .tailored-benefits .header {
    text-align: center;
    margin-top: 10em;
    margin-bottom: 5em;
  }
  
  .tailored-benefits .header h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
  }
  
  .tailored-benefits .header h3 {
    font-size: 35px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
    margin-bottom: 1em;
  }
  
  .tailored-benefits .header h2 span.colored {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .tailored-benefits .header .desc-text {
    font-size: 20px;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .tailored-benefits .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .tailored-benefits .content .element {
    border-bottom: 0.5px solid #A7A7A7;
  
  }
  
  .tailored-benefits .content .icon {
    margin-left: 1em;
  }
  
  .tailored-benefits .content>div {
    width: 50%;
  }
  
  .tailored-benefits .content .destop-image {
    width: 40%;
    height: 100%;
  }
  
  .accordion-toggle {
    color: #274C5B;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .accordion-toggle:hover {
    cursor: pointer;
  }
  
  .accordion-toggle .icon {
    float: right;
  
  }
  
  .accordion-content {
    display: none;
  }
  
  
  .tailored-benefits .destop-image {
    width: 40%;
  }
  
  .tailored-benefits .phone-image {
    display: none;
    width: 300px;
  }
  
  .tailored-benefits .phone-image img,
  .tailored-benefits .destop-image img {
    width: 100%;
  }
  
  .accordion-content.show {
    display: block;
  }
  
  .accordion-image.hide {
    display: none;
  }
  
  @media (max-width: 768px) {
    .tailored-benefits .header h2,
    .tailored-benefits .header h3 {
      font-size: 30px;
      text-align: left;
    }
    .mobile-none{
      display: none;
    }
  
    .tailored-benefits .header .desc-text {
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }
    
    .accordion-image.show {
      display: block;
  
    }
  
    .tailored-benefits .content>div {
      width: 100%;
    }
  
    .tailored-benefits .destop-image {
      display: none;
    }
  
    .tailored-benefits .phone-image {
      display: unset;
    }
  
  }