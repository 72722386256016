
h2{
    font-family: 'Poppins', sans-serif; 
}

.mail-farmsell{
    text-decoration: none;
}


.legal-hero{
 
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
    padding-right: 10%;
    padding-left: 10%;
    background-color: #D7FFE8;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 100px;
   
}

.legal-textsCont{
    width: 60%;
}

.legal-imageCont{
    width: 50%;
}

.legal-image{
    width: 90%;
}

.title-legal{
    color: #274C5B;
    font-size:40px;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    
}

.legal-p{
    font-size: 25px;
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    line-height: normal;
}

.button-container{
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.button-container button{
    color: #ffffff;
    font-size: 20px;
    background: none;
    border: none;
    margin: 0 30px;
}

.active{
    font-weight: 600;
    text-decoration: underline;
}

.separator{
    border: 1px solid #ffffff;
    height: 20px;
    margin: 0 5px;
} 

.terms-container{
    padding: 0 20%;
    margin-top: 30px;
}
.term-title{
    color: #274C5B;
    font-size: 2.8rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;

}

.terms-list{
    font-size: 1.8rem;
    color: #3FB871;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 650;
    text-align: start;
}

.term-title{
    text-align: center;
    font-size: 1.8rem;
    color: #3FB871;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 600;
}

.par-terms{
    font-size: 1.2rem;
    color: #274C5B;
    justify-content: last baseline;
    text-align: justify;
}

.terms-ul{
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
    color: #274C5B;
    font-weight: 500;
}

.terms-ul2{
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
    color: #274C5B;
    font-weight: 600;
    

}

@media (max-width: 800px) {
    .legal-hero{
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 3%;
        padding-left: 3%;
    }

    .legal-textsCont{
        width: 100%;
    }

    .title-legal{
        color: #274C5B;
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 30px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        
    }
    
    .legal-p{
        font-size: 20px;
        color: #274C5B;
        font-family: 'Poppins', sans-serif;
        line-height: normal;
    }

    .legal-imageCont{
        width: 100%;
    }
    
    .legal-image{
        width: 90%;
    }

    .terms-container{
        padding: 0 0;
        margin-top: 30px;
    }

    .terms-list{
 
        font-weight: 650;
        margin-left: 5%;
        justify-content: start;
        font-size: 20px;
    }

    .par-terms{
        font-size: 1.2rem;
        color: #274C5B;
        justify-content: start;
        text-align: left;
        margin-left: -10px;
        padding-right: 5%;
    }

    .par-terms{
        font-size: 1.1rem;
        color: #274C5B;
        justify-content: last baseline;
        text-align: justify;
    }
    
    .terms-ul{
        list-style: none;
        padding: 0;
        margin-bottom: 20px;
        font-size: 15px;
        color: #274C5B;
        font-weight: 500;
    }
    
    .terms-ul2{
        list-style: none;
        padding: 0;
        padding-right: 3%;
        margin-bottom: 20px;
        font-size: 15px;
        color: #274C5B;
        font-weight: 600;
        
    
    }
    

    
}