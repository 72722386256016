.f-c {
    width: 100%;
    margin-top: 100px;
}

.f-wrapper {
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

}

.f-header {
    width: 621px;
height: 162px;
flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.f-header > h2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
    color: rgba(39, 76, 91, 1);

}

.f-w {
    width: 100%;
    display: flex;
    gap: 100px;
    margin-top: 60px;

}

.f-img {
    width: 100%;

}

.f-images {

 width: 100%;
height: 365px;
flex-shrink: 0;
}
.f-para{
    width: 100%;
height: 208px;
flex-shrink: 0;
}
.f-text {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.f-para > p {
    color: #525C60;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.f-btnW{
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
}
.f-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3FB871;
    width: 300px;
    height: 50px;

    padding: 40px;
    border-radius: 5px;
    gap: 5px;

    color: #fff;
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    padding: 15px;
    border: none;

}

.bg-text {
    background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%),
        linear-gradient(0deg, #274C5B, #274C5B);

    -webkit-background-clip: text;
    color: transparent;
}

@media (max-width: 768px) {
    .f-w {
        flex-direction: column;
        height: auto;

    }
    .f-img {
        width: 100%;
        display: flex;
    justify-content: center;
    }

    .f-images {

        width: 100%;
height: 243px;
flex-shrink: 0;
    }
    .f-para{
        width: 100%;
        height: 208px;
        flex-shrink: 0;
    }

    .f-wrapper {
        margin: 0;


    }
    .f-header {
        width: 100%;
        height: 162px;
        flex-shrink: 0;
    }
    .f-header > h2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .f-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */
    }

    .f-btn {
       margin-top: 130px;
    
    }

    .f-text {
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

}

@media (max-width: 820px) {
    .f-w {
        flex-direction: column;

    }

    .f-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */
        padding: 30px;
    }

    .f-h {

        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;

    }

}

@media (max-width: 320px) {

    .f-w {
        flex-direction: column;

    }

    .f-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */
    }

    .f-h {

        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}