/* WhyFarmSell.css */
.why-farm-sell {
    font-family: 'Poppins', sans-serif;
    /* background-color: #F9F8F8;
     */
    background-image: linear-gradient(to top, #F9F8F8, #FFFFFF);
}

.why-farm-sell .heading {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3em;

}

.why-farm-sell .heading h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
    margin-bottom: 0.8em;
}

.why-farm-sell .heading h2 span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}


.why-farm-sell .heading .desc-text {
    font-size: 20px;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1.5em;
}

.why-farm-sell .content {
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    justify-content: space-between;

}

.why-farm-sell .card {
    width: 33%;
    min-height: 30em;
    margin-top: 2em;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.9;
    position: relative;
    border: 0;
    transition: background-color 2s ease;
    cursor: pointer;
    overflow: hidden;

}

.why-farm-sell .card:hover {
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}


.why-farm-sell .content .texts {
    position: absolute;
    color: #274C5B;
    background: rgba(255, 255, 255, 0.8);
    padding-left: 5px;
    padding-right: 5px;
    margin: 1rem;
    border-radius: 10px;
    transition: width 0.5s ease, padding-top 2s ease;
}

.why-farm-sell .content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.why-farm-sell .card:hover .texts {
    background-color: #fff;
    border: 2px solid #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
    text-align: left;
    padding: 2em;
    opacity: 1;

}

.why-farm-sell .card .temp-h2 {
    background-color: rgba(255, 255, 255, 0.9);
    margin-left: 5%;
    margin-right: 5%;
    padding: 20px;
    border-radius: 10px;
}

.why-farm-sell .card:hover .temp-h2 {
    display: none;
}

.why-farm-sell .card h2 {
    text-align: center;
    font-size: 27px;
    color: #274C5B;
    font-weight: 600;
    margin: unset;
}

.why-farm-sell .card .conc-p {
    text-align: left;
    color: #525C60;
    margin-left: 30px;
    margin-right: 20px;

}

.why-farm-sell .card .conc-h2 h2,
.why-farm-sell .card p {
    margin-bottom: 1em;
}

.why-farm-sell .content .card .card-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 1s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.why-farm-sell .content .card:hover .card-content {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
}


@media (max-width: 768px) {
    .why-farm-sell .heading {
        margin-left: 10%;
        margin-right: 0;
    }

    .why-farm-sell .heading h2 {
        font-size: 26px;
        text-align: left;
    }

    .why-farm-sell .heading .desc-text {
        font-size: 15px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    }

    .why-farm-sell .content {
        flex-direction: column;
    }
    

    .why-farm-sell .card {
        height: auto;
        justify-content: unset;
    }

    .why-farm-sell .content .card .card-content {
        position: relative;
        transform: none;
        opacity: 1;
    }

    .why-farm-sell .card .temp-h2 {
        display: none;
    }
    .why-farm-sell .card .conc-h2{
        font-size: 25px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .why-farm-sell .card .conc-p{
        font-size: 15px;
        font-weight: 300;
    }

    .why-farm-sell .content .card .card-content {
        left: 0;
        padding: 20px 2px;
    }
    .why-farm-sell .content .card:hover .card-content{
        transform: none;
        background-color: rgba(255, 255, 255, 0);
    }

    .why-farm-sell .card h2 {
        text-align: left;
        font-size: 25px;
        color: #fff;
        margin: unset;
    }

    .why-farm-sell .card p {
        text-align: left;
        color: #fff;
        font-size: 15px;
        margin-top: 2em;
    }

    .why-farm-sell img {
        display: none;
    }

    .why-farm-sell .content .texts {
        margin-bottom: 1em;
        background: unset;
        color: #fff;
    }

    .why-farm-sell .card:hover .texts {
        text-align: left;
        background-color: unset;
        width: unset;
        height: unset;
        border-radius: 0;
        border: 0;

    }

    .why-farm-sell .card {
        background: none;
        transform: none;
        margin-top: 1em;
        width: 100%;
        text-align: left;
        /* Remove scaling in mobile view */
    }

    .why-farm-sell .card p {
        display: unset;
    }

}




