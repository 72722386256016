/* OurServices.css */
.our-services{
    background-color: #F9F8F8;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 2em;

}
.our-services>div {
    margin-left: 10%;
    margin-right: 10%;   
}

.our-services .header {
    text-align: center;
    padding-top: 10em;
}

.our-services .header h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
}
.our-services .header h2 span{
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
}

.our-services .header .desc-text {
    font-size: 20px;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;

}

.our-services .content {
    display: flex;
    margin-top: 5em;
    margin-bottom: 5em;
}

.our-services .column {
    flex: 1;
    box-sizing: border-box;
}
.our-services .element{
    margin-top: 1em;
}

.our-services .element h2{
    font-size: 25px;
    font-weight: 600;
    color: #274C5B;
}
.our-services .element p{
    color: #525C60;
    font-size: 15px;
}

.our-services .element.lower {
    margin-top: 3em;
}

.our-services .icon {
    font-size: 24px;
    margin-bottom: 8px;
}

.our-services .icon.right {
    display: flex;
    justify-content: end;
}

.our-services .text.right {
    text-align: right;
}

.our-services .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.our-services .phoneimage {
    margin: 20px;
}


@media (max-width: 768px) {

    .our-services .header h2 {
        font-size: 30px;
        text-align: left;
    }
    .our-services .header .desc-text {
        font-size: 14px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    
    }

    .our-services .content {
        flex-direction: column;
    }


    .our-services .text.right {
        text-align: left;
    }

    .our-services .icon {
        margin-bottom: 8px;
    }

    .our-services .icon.right {
        justify-content: start;
    }


    .our-services .phoneimage {
        display: none;
    }
}