.w-c {
    width: 100%;
    margin-top: 100px;
}

.w-wrapper {
    margin-left: 10%;
    margin-right: 10%;

}

.w-header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.w-header > h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(39, 76, 91, 1);

}

.w-w {
    width: 100%;
    display: flex;
    gap: 100px;
    margin-top: 60px;

}

.w-img {
    width: 100%;
}

.w-images {}

.w-text {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.w-para{
    width: 539px;
height: 208px;
flex-shrink: 0;
}
.w-para > p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(82, 92, 96, 1);
    ;
}

.w-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3FB871;
    width: 300px;
    height: 50px;

    padding: 40px;
    border-radius: 5px;
    gap: 5px;
    margin-top: 60px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  
    padding: 15px;
    border: none;

}

.bg-text {
    background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%),
        linear-gradient(0deg, #274C5B, #274C5B);

    -webkit-background-clip: text;
    color: transparent;
}

@media (max-width: 768px) {


    .w-w {
        flex-direction: column;

    }

    .w-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */
    }
    .w-header {
        width: 100%;
        height: 162px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }
    .w-header > h2 {
        color: var(--sec, #274C5B);
        font-family: 'Poppins', sans-serif;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
    
    }
    .w-btn {
       margin-top: 90px;
    
    }
    .w-para{
        width: 100%;
        height: 208px;
        flex-shrink: 0;
    }
    .w-h {

        font-size: 30px;
        line-height: 30px;


    }
    .w-text {
       gap: inherit;
    }

}

@media (max-width: 820px) {
    .w-w {
        flex-direction: column;

    }
    .w-btn {
        margin-top: 90px;
     
     }
    .w-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        /* 160% */
       

    }
    .w-header > h2 {
        color: var(--sec, #274C5B);
        font-family: 'Poppins', sans-serif;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
    
    }
}

@media (max-width: 320px) {

    .w-w {
        flex-direction: column;

    }

    .w-p {

        color: var(--Neutral-Grey, #717171);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 160% */
    }

    .w-h {

        font-size: 30px;
        line-height: 30px;


    }

}