/* HeroSection1.css */

.container-HeroSection {
  display: flex;
  background-color: #DAFAE4;

  padding-top: 18%;
  padding-bottom: 5%;
}

.textContainerhere {
  flex: 2 1;
  margin-right: 15%;

  margin-left: 10%;
  margin-top: 7%;
}

.imagesContainer {
  flex: 1;
}

.samplse {
  max-width: 170%;
  height: auto;
  width: 142%;
  margin-left: -56%;
}


.Cultivaed,
.Premier,
.Prem,
.B2B {
  font-family: 'Poppins', sans-serif;
  color: #274C5B;
}

.Reward {
  color: #274C5B;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.2px;
  margin-top: 27px;
}

.Cultivaed {
  font-size: 50px;
  font-weight: 600;
  line-height: 61px;
}

.Premier {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.Prem,
.B2B {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .container-HeroSection {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .textContainerhere {
    margin-right: 0;
  }
}

/* Media query for small screens (max-width: 600px) */
@media (max-width: 600px) {
  .container-HeroSection {
    padding-top: 15%;
    /* Adjusted padding for small screens */
  }

  .textContainerhere {
    margin-right: 0;
    margin-left: 0;
  }

  .Cultivaed {
    font-size: 24px;
    text-align: left;
    margin-left: 5%;
  }

  .Premier {
    font-size: 18px;
    text-align: left;
    margin-left: 5%;
    line-height: 1.4;
  }

  .Reward {
    margin-left: 5%;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }

  .samplse {
    width: 100%;
    /* Set the width to 100% on small screens */
    height: auto;
    margin-left: 0;
  }
}

/* Media query for medium screens (601px - 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
  .container-HeroSection {
    padding-top: 12%;
    /* Adjusted padding for medium screens */
  }

  .textContainerhere {
    margin-right: 8%;
    margin-left: 5%;
    margin-top: 7%;
  }

  .Cultivaed {
    font-size: 36px;
    text-align: left;
  }

  .Premier {
    font-size: 28px;
    text-align: left;
  }

  .Reward {
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.2px;
    margin-top: 27px;
    text-align: left;
  }

  .samplse {
    max-width: 120%;

  }

  .samplse {
    max-width: 170%;
    height: auto;
    width: 142%;
    margin-left: -22%;
  }
}

/* Media query for large screens (min-width: 1025px) */
@media (min-width: 1025px) {
  .container-HeroSection {
    padding-top: 11%;
  }

  .textContainerhere {
    margin-right: 15%;
    margin-left: 10%;
    margin-top: 5%;
  }

  .Cultivaed {
    font-size: 48px;
    
  }

  .Premier {
    font-size: 40px;
  }

  .samplse {
    max-width: 140%;
    display: block;
  }
}