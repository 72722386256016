.contact-banner {
    background-color: #D7FFE8;
    font-family: 'Poppins', sans-serif;
}

.contact-banner .cont-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 6%;
    padding-bottom: 6%;
}

.contact-banner .text-cont {
    text-align: left;
}

.contact-banner .text-cont h2 {
    color: #274C5B;
    font-size: 50px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.5em;
}

.contact-banner .text-cont h2 span {
    background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-banner .text-cont .desc-text {
    color: #274C5B;
    font-size: 30px;
    font-weight: 400;
}

.contact-banner .img-cont img{
    width: 100%;
}


.get-container {
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    margin-top: 5em;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 15em;
}

.get-container .sect-one {
    width: 48%;
}

.get-container .sect-one .section-content {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.get-container .sect-one h3 {
    color: #274C5B;
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0.5em;

}

.get-container .sect-one h3 span {
    background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.get-container .desc-text-sect1 {
    color: #274C5B;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}

.get-container .items {
    margin-top: 3em;
    display: table;
    padding: 0.8em;
    border-radius: 20px;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.0),
    0 6.7px 5.3px rgba(0, 0, 0, 0.008),
    0 12.5px 10px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.032),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
}

.get-container .items .item {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid #CFCFCF;
    padding-top: 1em;
    padding-bottom: 1em;
}
.get-container .items .item.last {
    border-bottom: 0;
}


.get-container .items .item .icon img {
    width: 80%;
}

.get-container .items .item .name {
    margin-left: 5%;
    color: #274C5B;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
}

.get-container .items .item .src {
    margin-left: auto;
    color: #606060;
    font-size: 20px;
    font-weight: 400;
}

.get-container .social-media-icons ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-top: 3em;
}

.get-container .social-media-icons ul li {
    margin-right: 10px;
}

.get-container .social-media-icons ul li:hover {
    cursor: pointer;
}

.get-container .sect-two {
    width: 48%;
}

.get-container .cont-form {
    border-radius: 20px;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.0),
    0 6.7px 5.3px rgba(0, 0, 0, 0.008),
    0 12.5px 10px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.032),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 60px 50px rgba(0, 0, 0, 0.1)
  ;
}

.get-container .element {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 1.2em;
    margin-top: 0.5em;
}

.get-container .element label {
    color: #274C5B;
    font-size: 18px;
    font-weight: 600;
}

.get-container .element input {
    border-radius: 5px;
    border: 1px solid #D3D3D3;
    color: #B5C8D0;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
}

.get-container .element input.one {
    width: 48%;
    margin-right: 4%;
}

.get-container .element input.two {
    width: 48%;
}


.get-container .element textarea {
    outline: none !important;
    border-radius: 5px;
    border: 1px solid #D3D3D3;
    color: #B5C8D0;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    height: 200px;
}

.get-container .sect-two .send-button {
    display: flex;
}

.get-container .sect-two .send-button button {
    display: flex;
    padding: 10px 30px;
    align-items: center;
    border-radius: 10px;
    background: #3FB871;
    border: none;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 10%;
    margin-top: 1em;
    margin-bottom: 1.5em;

}

.get-container .sect-two .send-button button:hover {
    background: #F7B618;
}

.get-container .sect-two .send-button button img {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .contact-banner .cont-container {
        flex-direction: column;
    }

    .get-container {
        flex-direction: column;
    }

    .contact-banner .text-cont h2 {
        font-size: 30px;
        margin-top: 32%;
    }
    .contact-banner .text-cont .desc-text {
        font-size: 18px;
        margin-top: 2%;
    }
    .get-container .sect-one,
    .get-container .sect-two {
        width: 100%;
    }

    .get-container .sect-one h3 {
        font-size: 30px;    
    }

    
    .get-container .items .item .name {
        font-size: 18px;
        font-weight: 600;
    }
    
    .get-container .items .item .src {
        color: #606060;
        font-size: 16px;
        font-weight: 400;
        display: none;
    }
    .get-container .cont-form{
        margin-top: 5em;
    }
    .get-container .cont-form,
    .get-container .items{
        box-shadow: unset;
        padding: 0;
    }
    .get-container .element {
        margin-left: 0;
        margin-right: 0;
    }
    .get-container .element label {
        color: #274C5B;
        font-size: 18px;
        font-weight: 600;
    }
    
    .get-container .element input {
        font-size: 12px;
        font-weight: 400;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .get-container .element textarea {
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 200px;
    }
    .get-container .social-media-icons{
        display: none;
    }
    .get-container .sect-two .send-button button {
        margin-right: 0;
        width: 100%;  
        justify-content: center;
    }

}