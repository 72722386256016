.firstContainer {
    height: 500px;
    background-color: rgba(246, 246, 246, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 45px;

    padding-left: 10%;
    padding-right: 10%;
}

.header-join-container {
    display: flex;
    align-items: center;
    height: 80px !important;
}

.container {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
    margin-left: 20%;
}

.container-join-farmsell-page {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.item-container {
    width: 32%;
    background-color: rgba(220, 241, 229, 1);
    height: 270px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex-direction: column;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 3%;
}
.item-container-join {
    width: 345px;
    background-color: rgba(220, 241, 229, 1);
    height: 345px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;
}

.item-container-join:hover {
    width: 345px;
    background-color: white;
    height: 345px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-join-page {
    width: 260px;
}

.item-container:hover {
    width: 32%;
    background-color: white;
    height: 270px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-container:hover .btn-img {
    margin-top: 30px;
    width: 90px;
    border-radius: 5px;
    padding: 5px;
    border: 0px;
    background-color: rgba(247, 182, 24, 1);
    color: white;
}
.item-container-join:hover .btn-img {
    margin-top: 30px;
    width: 90px;
    border-radius: 5px;
    padding: 5px;
    border: 0px;
    background-color: rgba(247, 182, 24, 1);
    color: white;
}

.img-item {
    width: 60px;
}

.btn-img {
    margin-top: 30px;
    width: 90px;
    border-radius: 5px;
    border: 0px;
    background-color: rgba(220, 241, 229, 1);
    color: rgba(247, 182, 24, 1);
}

.header-list {
    color: rgba(63, 184, 113, 1);
    margin-top: 10px;
}

.list-container {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: "10%";
}
.list-container1 {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
}
.linear-color {
    background: linear-gradient(to left, #3fb871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.join-container-list {
    display: flex;
    flex-direction: row;

    margin-bottom: 20px;
    margin-bottom: 20px;
}

.join-btn-page {
    color: #ffff;
    border: none;
    background-color: #3fb871;
    border-radius: 10px;
    padding: 10px ;
    width: 180px;
    margin-left: auto;
    cursor: pointer;
    margin-right: 10%;
}

.join-header-icon {
    margin-left: 10%;
}

.title-join-page {
    padding-bottom: 20px;
    margin-top: 60px;
    margin-bottom: 40px;
    margin-left: -16%;
    display: block;
}
.harmburg-icon-container {
    display: none;
}
.logo-header{
    width: 60%;
}

.title-join-page-mobile {
    display: none;
}

@media (max-width: 768px) {
    .join-btn-page {
        display: none;
    }
    .join-header-icon {
        margin-left: 15px;
    }
    .logo-header {
        width: 50%;
    }
    .harmburg-icon-container {
        display: block;
        margin-left: auto;
        margin-right: 25px;
    }

    .header-join-container {
        height: 74px !important;
    }

    .title-join-page {
        display: none;
    }

    .title-join-page-mobile {
        padding-bottom: 20px;
        margin-top: 10px;
        margin-bottom: 30px;
        display: block;
    }
    .hamburger-join-farmsell {
        height: 20px;
    }
    .join-container-list {
        display: flex;
        flex-direction: column;

        margin-bottom: 20px;
        margin-bottom: 20px;
    }

    .item-container-join {
        margin-top: 10px;
        width: 100%;
    }

    .menu-item {
        text-decoration: none;
        color: rgba(93, 90, 136, 1);
        cursor: pointer;
   
    }

    .join-btn-page-harmbug{

        color: #ffff;
        border: none;
        background-color: #3fb871;
        border-radius: 10px;
        padding: 10px;
        width: 180px;
    
        cursor: pointer;
        
    }
}
