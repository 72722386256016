.connect-c {
    /* width: 1254px; */
    height: 264.492px;
    display: flex;
    justify-items: center;
    align-items: center;
    transform: translateY(-120px);



}

.wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
    border-radius: 10px;

}

.connect-text {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    padding: 40px;
    gap: 10px;
}

.connect-h {
    color: rgba(255, 255, 255, 1);
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;



}

.connect-p {
    color: rgba(255, 255, 255, 1);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}

.btn-connect {
    display: flex;
    justify-content: center;
    align-items: center;
   padding: 8px;
    border-radius: 10px;
    border: 1px;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
    background: #3FB871;
    width: 40%;

}

/* .btn:hover {
    color: rgba(255, 255, 255, 1)
} */

.connect-img {
    margin-top: 60px;
    width: 100%;

}

.connect-images {
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .connect-c {
        margin-top: 0;
        transform: translateY(191px);
        margin-bottom: 500px;
    }

    .wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .connect-h {

        font-size: 30px;
        font-weight: 600;
        line-height: 26px;
    }

    .connect-images {

        display: inline-flex;
        flex-direction: column;
        align-items: center

    }
br{
    display: none;
}
    .btn {

        display: flex;
        justify-content: center;
        align-items: center;
        /* padding: 18px 40px; */
        border-radius: 10px;
        /* border: 1px; */
        /* gap: 10px; */
        border: 1px solid rgba(255, 255, 255, 1);
        color: rgba(255, 255, 255, 1);
        background: #3FB871;
        width: 40%;
    }
    }

    .connect-img {
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }



@media (max-width: 820px) {
    .wrapper {
        display: flex;
        width: 100%;
        height: 90vh;
        flex-shrink: 0;
        flex-direction: column;
        margin: 0;
    }

    .connect-h {

        font-size: 30px;
        font-weight: 600;

        line-height: 30px;



    }

    .connect-p {
        color: var(--Color, #FFF);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    .btn {

        width: 100%;
        text-align: center;
        justify-content: center;



    }

    .connect-img {

        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .connect-text {
        width: 100%;
        margin: 0;
    }

    .connect-images {
        width: 100%;
    }

}

@media (max-width: 320px) {


    .wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .connect-h {

        font-size: 30px;
        font-weight: 600;
        text-align: center;



    }

    .btn {

        width: 100%;
        text-align: center;
        justify-content: center;



    }

}