/* ElevateYour.css */

.elevate-your-container {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    /* Set your desired background color */
    border-radius: 10px;
    /* Set border radius for the container */
    overflow: hidden;
    padding: 20px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.elevate-your-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 129%;
}

.Elevate {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    color: #FFFFFF;
}

.elevate-your-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #FFFFFF;
    padding-left: 4%;


}

.JoinNows {
    border: 2px solid #FFFFFF;
    background-color: #3fb871;
    color: #fff;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Poppins", sans-serif;

}

.JoinNows:hover {
    background-color: #f7b618;
    border-radius: 5px;
}

.elevate-your-text {
    flex: 1;
}

.elevate-your-image {
    flex: 1;
    max-width: 50%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: -20px;
    margin-top: 3%;

    

}

@media (max-width: 768px) {

    /* Media query for small screens */
    .elevate-your-content {
        flex-direction: column;
        /* Stack content vertically on small screens */
    }

    .elevate-your-image,
    .elevate-your-text {
        max-width: 100%;
        margin-right: 20%;
    }
}