.hero-section {
    background:#DEFFEC ;
    height: 100%;
    display: flex;
   
    font-family: 'Poppins', sans-serif;
    padding: 0 10%;
    



}

.hero-content {
    display: flex;
    margin-top: 5%;
    padding-bottom: 10%;

}

.hero-texts {

    margin-top: 10%;
    width: 100%;
    margin-right: 10%;
}

 .hero-h {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    width: 100%;
    margin-right: 300px;
    line-height: 1.3;

}

.hero-span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.hero-p {
    font-size: 30px;
    font-weight: 600;
    color: #274C5B;
    margin-top: 20px;
    width: 100%;
}

.hero-pdown {
    font-size: 30px;
    font-weight: 600;
    margin-top: -20px;
    color: #274C5B;
}

.hero-image {
    /* margin-left: 100px; */
    margin-top: 3%;
    width: 100%;
}

.hero-images{
    width: 100%;
}

@media (max-width: 700px) {

    .hero-section {
        padding: 0 3%;
    }

    .hero-content {
        display: grid;
        margin-top: 50px;
        padding: 0 1%;
    
    }

    .hero-content{
        display: flex;
        margin: 0 7%;
        flex-direction: column;
        margin-top: -100px;

    }
    .hero-texts {
    
        margin-top: 30px;
        width: 70%;
    }
    
    .hero-section .hero-h {
        font-size: 30px;
        font-weight: 600;
        color: #274C5B;
        width: 100%;
        margin-right: 300px;
        /* line-height: 50px; */
    
        margin-top: 30px;
        width: 60%;
    }

    .hero-p {
        font-size: 20px;
        font-weight: 600;
        margin-top: -20px;
        color: #274C5B;
    }
   

    .hero-image{
        margin-top: 0px;
       
        
     }

     .hero-images{
        width: 100%;
    }

    }
