.join-container {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: "Poppins", sans-serif;
}

.initalContainer {
    height: 530px;
    background-color: rgba(246, 246, 246, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 5em;
    padding-bottom: 3em;
    padding-left: 10%;
    padding-right: 10%;
}

.initalContainer h3 {
    font-size: 30px;
    color: #274c5b;
    font-weight: 600;
    padding-left: 10%;
    text-align: center;
    padding-bottom: 20px;
    padding-right: 10%;
}

.initalContainer h3 span {
    background: linear-gradient(to left, #3fb871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.initalContainer .desc-text {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
}

.cards-top-container {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: -200px;
}
.card-item-container {
    width: 32%;
    position: relative;
    background-color: rgba(220, 241, 229, 1);
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex-direction: column;
    padding: 25px 15px;
    cursor: pointer;
    border-radius: 10px;
    padding-bottom: 3em;
}

.card-item-container:hover {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.img-item-icon {
    width: 35%;
    margin-bottom: 1.5em;
}

.card-item-container .join-btn {
    margin-top: 30px;
    border-radius: 5px;
    width: 60%;
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    border: 0px;
    background-color: #dcf1e5;
    color: #f7b618;
    padding: 10px;
}

.card-item-container:hover .join-btn {
    background-color: #f7b618;
    color: white;
}

.card-item-container h4 {
    color: #3fb871;
    font-weight: 600;
    margin-bottom: 0.8em;
}

.card-item-container .cont-desc {
    color: #525c60;
    font-size: 15px;
    text-align: left;
    font-weight: 400;
    margin-bottom: 2em;
}

@media (max-width: 768px) {
    .join-container {
        margin-left: 0;
        margin-right: 0;
    }

    .initalContainer {
        height: auto;
        padding-bottom: 10em;
    }
    .initalContainer h3 {
        text-align: left;
        padding-left: 0;
    }
    .initalContainer .desc-text {
        text-align: left;
        font-size: 15px;
        font-weight: 400;
    }
    .cards-top-container {
        flex-direction: column;
        margin-top: -10em;
    }
    .card-item-container {
        width: 100%;
        margin-top: 30px;
    }
}
