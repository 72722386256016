.corevalues {
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5em;
}

.corevalues .header {
  text-align: center;
  padding-top: 10em;
}

.corevalues .header h2 {
  font-size: 45px;
  font-weight: 600;
  color: #274C5B;
  text-align: center;
}

.corevalues .header h2 span {
  background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corevalues .header .desc-text {
  font-size: 20px;
  text-align: center;
  color: #717171;
  margin-left: 10%;
  margin-right: 10%;

}


.corevalues .menu-details-container {
  display: flex;
  margin-top: 6em;

}

.corevalues .menu-container {
  width: 45%;
  margin-right: 5%;
  padding: 10px;
  display: flex;
  justify-content: center;

}

.corevalues .menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.corevalues .menu-container li {
  display: flex;
  align-items: center;
  color: #274C5B;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s;
  border-left: 10px solid #f9f9f9;
}
.corevalues .menu-container li .ca-active{
  width: 8px;
  height: 30px;
  border-radius: 5px;
  margin-right: 1em;
}


.corevalues .menu-container li.active {
  background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.corevalues .menu-container li.active .ca-active{
  background-color: #3FB871;

}

.corevalues .details-container {
  flex: 1;
  padding: 2em;
  border-radius: 5px;
  border: 2px solid #3FB871;
}

.corevalues .details-container h2 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
  background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corevalues .details-container p {
  color: #525C60;
  font-size: 18px;
  font-weight: 400;
}

.corevalues .menu-container li.active~.details-container h2 {
  transform: translateY(-50px);
}
.for-mobile{
  display: none;
}


@media (max-width: 768px) {
  .corevalues .for-web,
  .corevalues .menu-container {
    display: none;
  }

  .corevalues .for-mobile{
    display: unset;
  }
  .corevalues .header h2 {
    font-size: 30px;
    text-align: left;
  }
  
  
  .corevalues .header .desc-text {
    font-size: 18px;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  
  }

  .corevalues .menu-details-container {
    flex-direction: column;
  }

  .corevalues .details-container {
    width: 100%; 
    margin-bottom: 27px;
  }
  .corevalues .details-container h2{
    font-size: 18px;
    margin-bottom: 15px;
  }
  .corevalues .details-container p{
    font-size: 15px;
  }

}