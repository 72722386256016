



.s-c{
    width: 100%;
    margin-top:100px;
    margin-top: 12%;
    }
    .s-wrapper{
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .s-header{
        width: 621px;
        height: 162px;
        flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    }
    .s-header > h2{
        font-family: 'Poppins', sans-serif;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(39, 76, 91, 1);
        
    }
    
    .s-w{
    width: 100%;
    display: flex;
    gap: 100px;
   
    
    }
    .s-para{
        width: 100%;
        height: 208px;
        flex-shrink: 0; 
    }
    .s-img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    }
    .s-images{
        width: 1005;
        height: 532px;
        flex-shrink: 0;
    }
    .s-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-items: center;
        align-items: center;
        justify-content: center;
    }
    .s-para > p{
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(82, 92, 96, 1);
        ;
    }
    .s-btnw{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 44px;
    }
    .s-btn{
       display: flex;
       justify-content: space-between;
       align-items: center;
        background-color: #3FB871;
        width: 300px;
    height: 50px;
    
    padding: 40px;
    border-radius: 5px;
    gap: 5px;
    
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      margin-top: 20px;
      padding: 15px;
      border: none;
      
    }
    .bg-text{
        background: linear-gradient(102.88deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%),
        linear-gradient(0deg, #274C5B, #274C5B);
        
        -webkit-background-clip: text;
        color: transparent;
    }
    @media (max-width: 768px) {

        .s-w{
           flex-direction: column;
            
            }
            .s-para > p{
                color: #525C60;
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .s-h{
         
                font-size: 30px;
                
                
            }
            .s-para{
                width: 100%;
                height: 208px;
                flex-shrink: 0;
            }
            .s-images{
                width: 100%;
height: 392px;
flex-shrink: 0;
            }
            .s-header{
                width: 100%;
                height: 162px;
                flex-shrink: 0;
           
            }
            .s-header > h2{
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                
            }
            .s-btnw{
                display: flex;
                width: 100%;
                justify-content: flex-start;
            }
            .s-text{
                gap: inherit;
            }

    }
    
    @media (max-width: 820px) {
    
        .s-p{
         
            font-size:15 ;
            text-align: center;
        }
        .s-h{
         
            font-size: 30px;
            
            
        }
    }
    
    @media (max-width: 320px) {
    
        .s-p{
         
            font-size:15 ;
            text-align: center;
        }
        .s-h{
         
            font-size: 30px;
            
            
        }
    
    }