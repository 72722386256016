.our-header {
    position: relative;
    z-index: 999;
}

.main-header {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
}

.main-header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-left: 10%;
    margin-right: 10%;
}

.nav {
    display: flex;
    list-style: none;
}

.nav.open {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    justify-content: center;
    align-items: center;
}

.nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: -35%;
}

.nav li {
    padding: 0 1rem;
    color: #274c5b;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav li:hover {
    background-color: #c5fcdc; /* Hover background color */
    border-radius: 5px; /* Border radius for hover */
}

.join-btn {
    background-color: #3fb871;
    border: none;
    color: #fff;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Poppins", sans-serif;
}

.join-btn:hover {
    background-color: #f7b618; /* Hover background color */
    border-radius: 5px; /* Border radius for hover */
}

.join-btn.mobile {
    display: none;
}

.hamburger {
    display: none;
    cursor: pointer;
}

/* Mobile menu */
.mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}
.mobile-menu .menu {
    position: relative;
    height: 100vh;
}

button.join-btn2 {
    padding: 0.8rem 2rem;
    background-color: #3fb871;
    border: none;
    color: #fff;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 115px;
    display: flex;
    justify-content: center;
    left: 20%;
    right: 20%;
}
.mobile-menu .menu-link {
    margin-top: 10px;
}
.mobile-menu .menu-link ul {
    list-style-type: none;
    padding-left: 0;
}
.mobile-menu .menu-link ul li {
    padding-bottom: 10px;
    padding-top: 10px;
    color: #5d5a88;
    font-size: 18px;
    font-weight: 400;
    padding-left: 10%;
}
.mobile-menu .menu-link ul li:hover {
    background-color: #f9f9ff;
}
.header-main-web {
    display: block;
}
.header-main-mobile{
    display: none;
}

@media (max-width: 768px) {
    .nav,
    .nav ul {
        display: none;
    }
    .exi {
        margin-top: 3em;
        display: flex;
    }
    .exi img {
        margin-left: auto;
        margin-right: 3em;
    }

    .join-btn.web {
        display: none;
    }

    .join-btn.mobile {
        display: unset;
        padding: 0.25rem .8rem;
        font-size: 15px;
        font-weight: 600;
        border-radius: 5px;
    }

    .nav.open {
        display: flex;
        flex-direction: column;
    }

    .nav li {
        margin-bottom: 1rem;
    }
    .logo {
        width: 90px;
    }

    .hamburger {
        display: block;
        margin-left: 2em;
        
    }
    .header-main-web {
        display: none;
    }
    .header-mobile-home-container{
        display: flex;
    }

    .main-header > div {
        display: flex;
        justify-content: '';
        align-items: '';
        padding: 1rem;
        margin-left: 10px;
        margin-right: 6px;
    }
    .header-main-mobile{
        display: block;
    }
}
