#hero-community {
    background: #FFF7E5;
    width: 100%;
    height: 758px;
    flex-shrink: 0;

    display: flex;
    margin-bottom: 100px;
    font-family: 'Poppins', sans-serif;



}

#hero-content {
    display: flex;
    margin-top: 100px;
  margin-left: 10%;
  margin-right:10% ;
    width: 100%;
    gap: 12px;

}

#hero-texts {

    margin-top: 150px;
    width: 60%;
}

.hero-heading {
    width: 653px;
   
    flex-shrink: 0;
}

.hero-heading>h2 {
    font-size: 37px;
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */
    /* line-height: normal;
}

#hero-section {
    /* font-size: 50px;
    font-weight: 600;
    color: #274C5B;
    width: 100%;
    margin-right: 300px; */

}

#hero-span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.hero-pwarp {
    margin-top: 60px;
}

#hero-p {
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#hero-pdown {
    font-size: 30px;
    font-weight: 600;
    margin-top: -20px;
    color: #274C5B;
}

#hero-image {
    margin-top: 30px;
    width: 100%;

}

#hero-images {
    width: 100%;
    height: 641px;

}

@media (max-width: 768px) {

    #hero-community {
        width: 100%;

    }

    #hero-content {
        display: flex;
        flex-direction: column-reverse;
        /* margin-top: -100px; */
        width: 100%;
        /* margin: 0; */
        /* padding: 0; */
        gap: 2px;
        justify-content: center;



    }

    #hero-texts {

        margin-top: 150px;
        width: 100%;
        margin-top: 40px;
    }

    .hero-heading {
        width: 100%;
        height: auto;
    }

    .hero-pwarp {
        width: 100%;
        margin-top: 40px;
    }



    .hero-heading>h2 {
        color: #274C5B;
        font-family: 'Poppins', sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }



    #hero-image {
        margin-top: 4%;
        width: 100%;


    }

    #hero-images {
        width: 100%;
        height: 403px;
        flex-shrink: 0;
    }

    #hero-section.h1 {
        font-size: 25px !important;
    }
}

@media (max-width: 820px) {
    #hero-p {
        color: #274C5B;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}

@media (max-width: 320px) {

    #hero-p {
        font-size: 20px;
        font-weight: 600;
        color: #274C5B;
        margin-top: 50px;
        width: 500px;
    }
}