/* WhoWeAre.css */

.container-WhoWeAre {
  margin-top: 5%;
  display: flex;
  margin-left: 9%;
  margin-right: 6%;
  
}

.who {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  /* Adjusted font size for small screens */
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #274C5B;
  padding-bottom: 4%;
}

.We {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.heartbeat {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  /* Adjusted font size for small screens */
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  
  color: #525C60;
}

.imageContainer {
  margin-bottom: 10px;
  margin-left: 7%;
  Width: 300%;
  height: 477px;
}

.textContainer {
  margin-right: 5%;
  margin-left: -7%;
  margin-top: 5%;
}

/* Media query for small screens (max-width: 768px) */
@media (max-width: 768px) {
  .container-WhoWeAre {
    flex-direction: column;

  }

  .who {
    font-size: 24px;
    /* Further reduced font size for small screens */
  }

  .We {
    font-size: 20px;
    /* Adjusted font size for small screens */
  }

  .heartbeat {
    font-size: 14px;
    text-align: left;
    /* Further reduced font size for small screens */
  }

  .textContainer {
    text-align: center;
    margin-right: 0;
    margin-left: 0;
  }

  .imageContainer {
    width: 80%;
    margin-top: 20px;
    order: 1;
  }

  .sample1 {
    width: 100%;
    height: 294px;
  }
}