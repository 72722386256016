.how-it-works {
    width: 100%;
    /* height: 672px; */
    background-color: #DEFFEC;
    display: flex;
    padding-bottom: 100px;

}

.left-works {
    margin-left: 10%;
    margin-top: 50px;
    width: 100%;
}

.left-works .title-works {
    font-size: 45px;
    font-weight: bold;
    color: #274C5B;
}

.p-works {
    width: 100%;
    color: #525C60;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 40px;
}

.bullets-works1,
.bullets-works2,
.bullets-works3 {
    display: flex;
    align-items: center;
    color: #274C5B;
    font-size: 15px;
    font-weight: 500;
    width: 80%;


}

.p-bull {
    width: 100%;
    margin-left: 20px;
    line-height: 1.3;
    margin-top: 10px;
}

.but-works {
    margin-top: 70px;
    padding: 18px 40px;
    font-size: 20px;
    color: white;
    font-weight: 760;
    border: none;
    border-radius: 10px;
    background-color: #3FB871;
    cursor: pointer;

}

.but-works:hover {
    background-color: #F7B618;
    transition: background-color 0.3s ease;
}

.right-works {
    margin-right: 10%;
    margin-top: 100px;
    width: 100%;
}



@media (max-width: 768px) {

    .how-it-works {

        display: flex;
        flex-direction: column;

       


    }

    .left-works .title-works {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .p-works {
        width: 100%;
        color: #525C60;
        font-size: 15px;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .bullets-works1,
    .bullets-works2,
    .bullets-works3 {

        font-size: 15px;
        margin-left: -10px;


    }


    .but-works {
        margin-top: 70px;
        padding: 15px 30px;
        font-size: 20px;


    }

    .work-image {
        width: 200%;
    }

}

@media (max-width: 820px) {

    .how-it-works {

        display: flex;
        flex-direction: column;

       


    }

    .left-works .title-works {
        font-size: 50px;
        margin-bottom: 20px;
    }

    .p-works {
        width: 90%;
        color: #525C60;
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .bullets-works1,
    .bullets-works2,
    .bullets-works3 {

        font-size: 18px;
        margin-left: -10px;

    }

    .but-works {
        margin-top: 70px;
        padding: 15px 30px;
        font-size: 20px;


    }

    .right-works{
        width: 100%;
    }

    .work-image {
        width: 10%;
    }

}

@media (max-width: 800px) {

    .how-it-works {

        display: flex;
        flex-direction: column;


    }
    .left-works{
        padding-right: 10%;
    }

    .left-works .title-works {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .p-works {
        width: 100%;
        color: #525C60;
        font-size: 15px;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .bullets-works1,
    .bullets-works2,
    .bullets-works3 {

        font-size: 15px;
        margin-left: -10px;

    }

    .p-bull {
        width: 100%;
        height: 40px;

    }

    .but-works {
        margin-top: 70px;
        padding: 15px 30px;
        font-size: 20px;


    }

    .work-image {
        width: 100%;
    }

}